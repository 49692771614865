import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
} from "@mui/material";
import { useBotIntentsQuery, useBotQuery } from "app";
import { BotAvatar } from "components";
import type { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";

const Bot: FC = () => {
    const { id } = useParams();
    const { data: bot, isLoading } = useBotQuery(Number(id), { skip: !id });
    const { data: intents = [] } = useBotIntentsQuery(Number(id), {
        skip: !id,
    });

    if (!id || (!isLoading && !bot)) {
        return <Navigate to="/admin/bots" />;
    }

    if (isLoading || !bot) {
        return (
            <div className="p-4">
                <CircularProgress size={30} />
            </div>
        );
    }

    return (
        <div className="p-4 pb-8 md:pb-4">
            <div className="mb-4">
                <Link to="/admin/bots">
                    <Button variant="text" startIcon={<ChevronLeftIcon />}>
                        Back to all
                    </Button>
                </Link>
            </div>
            <div className="flex items-center mb-6">
                <BotAvatar name={bot.name} />
                <span className="ml-2">{bot.name}</span>
            </div>
            <div className="flex flex-col max-w-[400px]">
                <TextField label="Will send messages to" value={bot.email} />
                <FormControlLabel
                    className="mt-2"
                    control={<Checkbox checked={bot.photo_attachment} />}
                    label="Photo attachments allowed"
                />
                <FormControlLabel
                    control={<Checkbox checked={bot.video_attachment} />}
                    label="Video attachments allowed"
                />
            </div>
            <div>
                {intents.map((intent) => (
                    <Accordion key={intent.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {intent.name}
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="text-sm text-gray-500">
                                {intent.text}
                            </p>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
};

export default Bot;
