import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const Functions: FC = () => {
    const { t } = useTranslation();

    const handleWant = () =>
        document
            .querySelector("#form-section")
            ?.scrollIntoView({ behavior: "smooth" });

    return (
        <div className="flex pt-10">
            <section className="container flex flex-col h-full mx-auto">
                <h2 className="text-3xl font-bold text-center md:text-left">
                    {t("landingMainFunctionality")}
                </h2>
                <ul className="px-4 mt-10 md:px-0">
                    <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                        {t("landingFn1")}
                    </li>
                    <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                        {t("landingFn2")}
                    </li>
                    <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                        {t("landingFn3")}
                    </li>
                    <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                        {t("landingFn4")}
                    </li>
                </ul>
                <div className="flex justify-end pr-4 mt-12 md:pr-0">
                    <Button
                        size="large"
                        variant="contained"
                        onClick={handleWant}
                    >
                        {t("want")}
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default Functions;
