import { FC, useState } from "react";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useRequestBotMutation } from "app";

const RequestBot: FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [notes, setNotes] = useState("");
    const [requestBot, { isLoading }] = useRequestBotMutation();
    const { t } = useTranslation();

    const handleCreate = async () => {
        await requestBot({
            name,
            username,
        });

        setIsExpanded(false);
    };

    return (
        <div className="w-[300px] h-[320px] border border-gray-300 rounded shadow mx-auto md:mx-0 md:mr-4 mb-4 flex flex-col justify-center items-center">
            {isLoading ? (
                <CircularProgress size={30} />
            ) : isExpanded ? (
                <div className="w-full px-4">
                    <div className="mb-4">
                        <TextField
                            fullWidth
                            label="Name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <TextField
                            fullWidth
                            label="Username"
                            value={username}
                            onChange={(event) =>
                                setUsername(event.target.value)
                            }
                        />
                    </div>
                    <div className="mb-4">
                        <TextField
                            fullWidth
                            label="Notes"
                            value={notes}
                            onChange={(event) => setNotes(event.target.value)}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button onClick={handleCreate}>Create</Button>
                    </div>
                </div>
            ) : (
                <button
                    type="button"
                    className="flex flex-col items-center justify-center w-full h-full"
                    onClick={() => setIsExpanded(true)}
                >
                    <div className="rounded-full bg-[#009EFF] p-3 text-white">
                        <Add />
                    </div>
                    <span className="mt-4 text-sm">{t("requestNewBot")}</span>
                </button>
            )}
        </div>
    );
};

export default RequestBot;
