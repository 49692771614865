import { LoginRequest, LoginResponse, Me } from "./interfaces";
import { api } from "../api";

const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (body) => ({
                url: "login/",
                method: "POST",
                body: JSON.stringify(body),
            }),
        }),
        me: builder.query<Me, void>({
            query: () => "me",
        }),
    }),
});

export const { useLoginMutation, useMeQuery } = authApi;
