import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "icons/logo.svg";
import { Button } from "@mui/material";

const Heading: FC = () => {
    const { t } = useTranslation();

    const handleWant = () =>
        document
            .querySelector("#form-section")
            ?.scrollIntoView({ behavior: "smooth" });

    return (
        <div className="flex pt-10 md:min-h-screen">
            <section className="container flex flex-col h-full mx-auto">
                <h1 className="mb-4 text-3xl font-bold text-center md:mb-20 md:text-left">
                    {t("landingHeader")}
                </h1>
                <div className="flex flex-wrap items-center md:flex-nowrap">
                    <div className="px-4 -mt-8 md:mt-0 md:px-0 md:mr-8 w-full md:w-[40%]">
                        <Logo className="w-full" />
                    </div>
                    <div className="max-w-[780px] mx-auto px-4 md:px-0">
                        <p className="mb-6 text-xl">{t("landingIntro1")}</p>
                        <p className="mb-6 text-xl">{t("landingIntro2")}</p>
                        <p className="mb-6 text-xl">{t("landingIntro3")}</p>
                        <div className="flex justify-end mt-12">
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleWant}
                            >
                                {t("want")}
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Heading;
