import type { FC } from "react";

const Colors: FC = () => {
    return (
        <div className="absolute bottom-0 left-0 w-full opacity-25">
            <span className="w-full block bg-[#0057b7] h-1" />
            <span className="w-full block bg-[#ffd700] h-1" />
        </div>
    );
};

export default Colors;
