import { ReactComponent as SmartToy } from "@material-design-icons/svg/round/smart_toy.svg";

const navigation = [
    {
        path: "/admin/bots",
        text: "Bots",
        icon: SmartToy,
    },
] as const;

export default navigation;
