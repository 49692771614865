import { createBrowserRouter, Navigate } from "react-router-dom";
import { AdminLayout, AuthorizedLayout, UnauthorizedLayout } from "layouts";
import { Home, Login, Land } from "pages";
import * as Admin from "pages/admin";

const router = createBrowserRouter([
    {
        path: "/admin",
        element: (
            <AuthorizedLayout>
                <AdminLayout />
            </AuthorizedLayout>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="/admin/bots" replace />,
            },
            {
                path: "bots",
                element: <Admin.Bots />,
            },
            {
                path: "bots/:id",
                element: <Admin.Bot />,
            },
            {
                path: "users",
                element: <Admin.Users />,
            },
            {
                path: "sites",
                element: <Admin.Sites />,
            },
        ],
    },
    {
        path: "/login",
        element: (
            <UnauthorizedLayout>
                <Login />
            </UnauthorizedLayout>
        ),
    },
    {
        path: "/land",
        element: (
            <UnauthorizedLayout>
                <Land />
            </UnauthorizedLayout>
        ),
    },
    {
        path: "*",
        element: <Home />,
    },
]);

export default router;
