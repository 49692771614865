import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const Pricing: FC = () => {
    const { t } = useTranslation();

    const handleWant = () =>
        document
            .querySelector("#form-section")
            ?.scrollIntoView({ behavior: "smooth" });

    return (
        <div className="flex pt-10">
            <section className="container flex flex-col h-full mx-auto">
                <h2 className="text-3xl font-bold text-center md:text-left">
                    {t("prices")}
                </h2>
                <p className="px-4 mt-10 text-2xl text-gray-500 md:px-0">
                    {t("landingPlatformIsFree")}
                </p>

                <div className="flex justify-end pr-4 mt-12 md:pr-0">
                    <Button
                        size="large"
                        variant="contained"
                        onClick={handleWant}
                    >
                        {t("want")}
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default Pricing;
