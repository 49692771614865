import type { FC } from "react";
import type { BotAvatarProps } from "./interfaces";

const BotAvatar: FC<BotAvatarProps> = ({ name }) => (
    <div className="flex items-center justify-center w-10 h-10 text-xl font-bold text-white rounded-full min-w-[2.5rem] bg-gradient-to-br from-blue-500 to-purple-500">
        {name.charAt(0)}
    </div>
);

export default BotAvatar;
