import { Button } from "@mui/material";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { PublicBotProps } from "./interfaces";

const PublicBot: FC<PublicBotProps> = ({ bot }) => {
    const { t, i18n } = useTranslation();
    const description =
        i18n.language === "uk"
            ? bot.public_description_uk
            : bot.public_description;

    return (
        <article className="h-[400px] border border-gray-200 bg-white flex flex-col justify-between shadow-sm rounded-lg">
            <div>
                <header className="flex items-center px-6 py-4 mb-4">
                    <div className="flex items-center justify-center w-10 h-10 text-xl font-bold text-white rounded-full bg-gradient-to-br from-blue-500 to-purple-500">
                        {bot.name.charAt(0)}
                    </div>
                    <h2 className="ml-4 font-bold">{bot.name}</h2>
                </header>
                <p className="px-6 leading-relaxed text-gray-500">
                    {description}
                </p>
            </div>
            <div className="flex justify-end px-6 py-4">
                <Button
                    href={bot.telegram_url}
                    target="_blank"
                    variant="contained"
                >
                    {t("tryIt")}
                </Button>
            </div>
        </article>
    );
};

export default PublicBot;
