import { api } from "../api";
import { Bot, BotIntent, PendingBot, RequestBotPayload } from "./interfaces";

const botsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        bots: builder.query<Bot[], void>({
            query: () => "bots/",
        }),
        bot: builder.query<Bot, string | number>({
            query: (id) => `bots/${id}/`,
        }),
        botIntents: builder.query<BotIntent[], string | number>({
            query: (id) => `bots/${id}/intents/`,
        }),
        pendingBots: builder.query<PendingBot[], void>({
            query: () => "pending_bots/",
            providesTags: ["pending_bots"],
        }),
        requestBot: builder.mutation<void, RequestBotPayload>({
            invalidatesTags: ["pending_bots"],
            query: (body) => ({
                method: "POST",
                url: "pending_bots/",
                body: JSON.stringify(body),
            }),
        }),
    }),
});

export const {
    useBotQuery,
    useBotsQuery,
    useBotIntentsQuery,
    usePendingBotsQuery,
    useRequestBotMutation,
} = botsApi;
