import { FC } from "react";
import { useBotsQuery, usePendingBotsQuery } from "app";
import { Button, CircularProgress } from "@mui/material";
import cn from "classnames";
import { Link } from "react-router-dom";
import { BotAvatar } from "components";
import { RequestBot } from "./components";

const Bots: FC = () => {
    const { data: bots, isLoading } = useBotsQuery();
    const { data: pendingBots = [] } = usePendingBotsQuery();

    if (isLoading) {
        return (
            <div className="p-4">
                <CircularProgress size={30} />
            </div>
        );
    }

    if (!bots) {
        return <div className="text-red-500">Error occurred</div>;
    }

    return (
        <div className="p-4">
            <div className="flex flex-wrap">
                {bots.map((bot) => (
                    <div
                        key={bot.id}
                        className="w-[300px] h-[320px] mx-auto md:mx-0 border border-gray-300 rounded shadow md:mr-4 mb-4 flex flex-col justify-between"
                    >
                        <div>
                            <div className="flex items-center px-4 py-2 mb-6">
                                <BotAvatar name={bot.name} />
                                <h2 className="ml-4 font-bold">{bot.name}</h2>
                            </div>
                            <div className="px-4">
                                <p className="mb-2">
                                    <span>Status: </span>
                                    <span
                                        className={cn(
                                            "font-bold",
                                            bot.status === "OK" &&
                                                "text-green-500"
                                        )}
                                    >
                                        {bot.status}
                                    </span>
                                </p>
                                <p className="mb-2">
                                    <span>Messages last week: </span>
                                    <span className="font-bold">
                                        {/* @TODO: message_last_week should be used here, but it is absent in the response */}
                                        {bot.count_messages_sent}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-end px-4 py-2">
                            <Button style={{ marginRight: "8px" }}>
                                Broadcast
                            </Button>
                            <Link to={`/admin/bots/${bot.id}`}>
                                <Button>Setup</Button>
                            </Link>
                        </div>
                    </div>
                ))}
                {pendingBots.map((bot) => (
                    <div
                        key={bot.id}
                        className="w-[300px] h-[320px] border mx-auto md:mx-0 border-gray-300 rounded shadow md:mr-4 mb-4 flex flex-col justify-between"
                    >
                        <div>
                            <div className="flex items-center px-4 py-2 mb-6">
                                <BotAvatar name={bot.name} />
                                <div className="flex items-start justify-between w-full">
                                    <h2 className="ml-4 font-bold">
                                        {bot.name}
                                    </h2>
                                    <div className="px-2 py-1 mb-2 text-xs text-white bg-yellow-600 rounded">
                                        pending
                                    </div>
                                </div>
                            </div>
                            <div className="pl-4 text-sm text-gray-500">
                                This bot is being created.
                            </div>
                        </div>
                        <div className="flex justify-end px-4 py-2">
                            <Button style={{ marginRight: "8px" }}>
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
                <RequestBot />
            </div>
        </div>
    );
};

export default Bots;
