import { Button, Grid } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const FEEDBACKS = [
    {
        name: "David",
        text: "The platform is a tech symphony, blending Python's power with a user-friendly interface. To hit that sweet automation melody, consider jazzing up the customization options - it's the secret sauce to making each bot as unique as a bike ride through the Carpathians!",
        image: "/david.png",
    },
    {
        name: "Vitalii",
        text: "Платформа вражає своєю легкістю у використанні та інтуїтивно зрозумілим інтерфейсом, що робить процес створення ботів справжнім задоволенням. Це чудовий інструмент, який безумовно сподобається широкому колу користувачів!",
        image: "/vitalii.png",
    },
    {
        name: "Giuseppe",
        text: "Platform impresses with its remarkable ease of use and sleek, user-friendly design. It's like the smooth ride of a Trek bike, making the journey of bot creation not just efficient, but a delightful experience for users of all skill levels..",
        image: "/giuseppe.png",
    },
];

const Feedback: FC = () => {
    const { t } = useTranslation();

    const handleWant = () =>
        document
            .querySelector("#form-section")
            ?.scrollIntoView({ behavior: "smooth" });

    return (
        <div className="flex pt-10">
            <section className="container flex flex-col h-full mx-auto">
                <h2 className="text-3xl font-bold text-center md:text-left">
                    {t("landingFeedback")}
                </h2>
                <Grid container spacing={4} p={6}>
                    {FEEDBACKS.map((feedback) => (
                        <Grid key={feedback.name} item xs={12} md={4}>
                            <div className="flex flex-col items-center justify-center w-full h-full p-6 bg-white rounded shadow">
                                <img
                                    className="w-48 h-48 mb-4 rounded-full"
                                    src={feedback.image}
                                    alt={feedback.name}
                                />
                                <p className="my-4 text-xl font-bold">
                                    {feedback.name}
                                </p>
                                <p className="text-gray-500">{feedback.text}</p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <div className="flex justify-end pr-4 mt-12 md:pr-0">
                    <Button
                        size="large"
                        variant="contained"
                        onClick={handleWant}
                    >
                        {t("want")}
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default Feedback;
