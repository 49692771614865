import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getToken } from "./token";
import { API_URL } from "./url";

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        headers: {
            "Content-Type": "application/json",
        },
        prepareHeaders: (headers) => {
            const token = getToken();

            if (token) {
                headers.set("Authorization", `Token ${token}`);
            }

            return headers;
        },
    }),
    tagTypes: ["bots", "pending_bots"],
    endpoints: () => ({}),
});
