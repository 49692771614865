import { FC, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import router from "./router";
import { store } from "./store";
import { useMeQuery } from "./api";
import { clearToken } from "./api/token";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

const App: FC = () => {
    const { error } = useMeQuery();

    useEffect(() => {
        if (!error) {
            return;
        }

        if (
            "status" in error &&
            error.status === 403 &&
            (error.data as any)?.detail === "Invalid token."
        ) {
            clearToken();
        }
    }, [error]);

    return <RouterProvider router={router} />;
};

export default () => (
    <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </ReduxProvider>
);
