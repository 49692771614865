import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#009EFF",
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
        },
        MuiButton: {
            defaultProps: {
                size: "small",
                variant: "contained",
            },
        },
    },
});

export default theme;
