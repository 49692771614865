import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Link } from "react-router-dom";
import { clearToken } from "app/api/token";
import { HeaderProps } from "./interfaces";

const Header: FC<HeaderProps> = ({ showLogin, showLogout }) => {
    const { t, i18n } = useTranslation();

    const handleLogout = () => {
        clearToken();
        window.location.reload();
    };

    return (
        <header className="sticky top-0 z-20 bg-white border-b border-gray-200 h-[80px] md:h-[72px] flex flex-col">
            <div className="text-xs font-bold text-center bg-yellow-300 md:text-sm">
                {t("headerWarning")}
            </div>
            <div className="container flex items-center justify-between h-full mx-auto">
                <Link
                    to="/land"
                    className="pl-2 font-bold text-transparent md:pl-0 bg-clip-text bg-gradient-to-r from-sky-600 to-indigo-600"
                >
                    Bots platform
                </Link>
                <div className="flex items-center">
                    <ToggleButtonGroup
                        className="mr-8"
                        value={i18n.language}
                        exclusive
                        onChange={(_, value) => i18n.changeLanguage(value)}
                        size="small"
                    >
                        <ToggleButton value="en">English</ToggleButton>
                        <ToggleButton value="uk">Українська</ToggleButton>
                    </ToggleButtonGroup>
                    {showLogin && (
                        <Link
                            className="px-2 py-1 font-bold rounded-lg outline-none text-sky-500 focus:ring focus:ring-sky-500"
                            to="/login"
                        >
                            {t("login")}
                        </Link>
                    )}
                    {showLogout && (
                        <Button variant="text" onClick={handleLogout}>
                            {t("logout")}
                        </Button>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
