import { FC } from "react";
import {
    Examples,
    Form,
    Functions,
    Heading,
    HowItWorks,
    Feedback,
    Pricing,
    Contacts,
} from "./sections";

const Land: FC = () => {
    return (
        <>
            <Heading />
            <Functions />
            <HowItWorks />
            <Feedback />
            <Pricing />
            <Contacts />
            <Examples />
            <Form />
        </>
    );
};

export default Land;
