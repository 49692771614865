import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const HowItWorks: FC = () => {
    const { t } = useTranslation();

    const handleWant = () =>
        document
            .querySelector("#form-section")
            ?.scrollIntoView({ behavior: "smooth" });

    return (
        <div className="flex pt-10">
            <section className="container flex flex-col h-full mx-auto">
                <h2 className="text-3xl font-bold text-center md:text-left">
                    {t("howItWorks")}
                </h2>
                <div className="flex flex-wrap justify-between mt-10 md:flex-nowrap">
                    <div className="w-full px-4 md:w-1/2 md:mr-10 md:px-0">
                        <ul className="w-full">
                            <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                                {t("landingHow1")}
                            </li>
                            <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                                {t("landingHow2")}
                            </li>
                            <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                                {t("landingHow3")}
                            </li>
                            <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                                {t("landingHow4")}
                            </li>

                            <li className="w-full p-4 mb-4 text-xl border border-gray-300 rounded">
                                {t("landingHow5")}
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col flex-wrap items-end w-full px-4 md:px-0 md:w-1/2 md:flex-nowrap">
                        <div className="mb-10 border border-gray-300 max-w-full w-[560px] h-[300px] bg-gradient-to-br from-sky-100 to-purple-100"></div>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={handleWant}
                        >
                            {t("want")}
                        </Button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HowItWorks;
