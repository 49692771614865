import { getToken } from "app/api/token";
import type { FC } from "react";
import { Navigate } from "react-router-dom";

const Home: FC = () => {
    const isAuthorized = Boolean(getToken());
    const redirectTo = isAuthorized ? "/admin" : "/land";

    return <Navigate to={redirectTo} replace />;
};

export default Home;
