import { FC, FormEvent, useState } from "react";
import { useLoginMutation } from "app/api";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { setToken } from "app/api/token";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const Login: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [login, { isLoading }] = useLoginMutation();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setError("");

        const result = await login({
            username,
            password,
        });

        if ("data" in result) {
            setToken(result.data.token);
            navigate("/");
        } else if ("error" in result) {
            setError("Invalid credentials");
        }
    };

    return (
        <div className="flex items-center justify-center h-full min-h-[calc(100vh-72px-53px)]">
            <div className="shadow-lg bg-white border-gray-300 py-4 px-8 rounded-lg w-[400px] max-w-full">
                <h1 className="mb-8 font-bold text-center">
                    {t("loginIntoSystem")}
                </h1>
                <form
                    className={cn(
                        "transition duration-100",
                        isLoading && "opacity-70 pointer-events-none"
                    )}
                    onSubmit={handleSubmit}
                >
                    <div className="mb-6">
                        <TextField
                            fullWidth
                            label={t("username")}
                            value={username}
                            onChange={(event) =>
                                setUsername(event.target.value)
                            }
                        />
                    </div>
                    <div className="mb-6">
                        <TextField
                            fullWidth
                            type="password"
                            label={t("password")}
                            value={password}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                        />
                    </div>
                    <Button fullWidth variant="contained" type="submit">
                        {t("login")}
                    </Button>
                    <div className="mt-8 text-sm text-red-500 min-h-[20px]">
                        {error}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
