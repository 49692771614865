import type { FC } from "react";
import { NavLink, Outlet } from "react-router-dom";
import HelloAdmin from "./HelloAdmin";
import navigation from "./navigation";
import cn from "classnames";
import Colors from "./Colors";
import { Header } from "organisms";

const AdminLayout: FC = () => (
    <div className="flex flex-col md:w-screen md:h-screen">
        <Header showLogout />
        <div className="flex flex-wrap p-4 md:h-full md:flex-nowrap">
            <div className="md:min-w-[360px] md:w-[360px] w-full flex flex-col justify-between h-full bg-white md:mr-4 mb-4 md:mb-0 rounded-lg drop-shadow-lg border border-gray-300">
                <nav className="flex flex-col">
                    <ul className="flex flex-col p-4 font-bold list-none">
                        {navigation.map(({ path, text, icon: Icon }, index) => (
                            <NavLink
                                key={index}
                                to={path}
                                className={({ isActive }) =>
                                    cn(
                                        "rounded-lg p-2 mb-2 transition duration-100 flex items-center",
                                        isActive
                                            ? "bg-sky-500 text-white"
                                            : "hover:text-sky-400"
                                    )
                                }
                            >
                                <Icon className="mr-2" />
                                <span>{text}</span>
                            </NavLink>
                        ))}
                    </ul>
                </nav>
                <HelloAdmin />
                <Colors />
            </div>
            <div className="w-full h-full max-h-full overflow-y-auto bg-white border border-gray-300 rounded-lg drop-shadow-lg">
                <Outlet />
                <Colors />
            </div>
        </div>
    </div>
);

export default AdminLayout;
