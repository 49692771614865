import type { FC } from "react";
import { useMeQuery } from "app";

const HelloAdmin: FC = () => {
    const { data: me } = useMeQuery();

    if (!me) {
        return null;
    }

    return (
        <div className="flex items-center justify-between p-6">
            <span>Hello, {me.username}!</span>
        </div>
    );
};

export default HelloAdmin;
