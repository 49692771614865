import type { FC } from "react";
import { useMeQuery } from "app";
import { Navigate } from "react-router-dom";

const AuthorizedLayout: FC = ({ children }) => {
    const { data: me, isLoading, isError } = useMeQuery();

    if (isLoading) {
        return null;
    }

    if (isError || !me) {
        return <Navigate to="/login" />;
    }

    return <>{children}</>;
};

export default AuthorizedLayout;
