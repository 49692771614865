import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const CONTACT_EMAIL = "vitalii@vitalii.tech";

const Contacts: FC = () => {
    const { t } = useTranslation();

    const handleWant = () =>
        document
            .querySelector("#form-section")
            ?.scrollIntoView({ behavior: "smooth" });

    return (
        <div className="flex pt-10">
            <section className="container flex flex-col h-full mx-auto">
                <h2 className="mb-10 text-3xl font-bold text-center md:text-left">
                    {t("contacts")}
                </h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="flex flex-col items-center mx-auto md:mr-14 md:ml-0">
                        <div className="w-full rounded-full md:w-48 md:h-48 bg-gradient-to-br from-sky-200 to-yellow-200">
                            <img src="/vitalii.png" alt="vitalii" />
                        </div>
                        <p className="mt-4 text-2xl md:text-lg">
                            {t("landingContactName")}
                        </p>
                    </div>
                    <div className="px-4 mt-4 md:mt-0 md:px-0">
                        <div className="text-2xl max-w-[640px] text-gray-500">
                            {t("landingContactText")}
                        </div>
                        <div className="mt-4 text-xl">
                            <span>{t("email")}: </span>
                            <a
                                className="text-sky-400"
                                href={`mailto:${CONTACT_EMAIL}`}
                            >
                                {CONTACT_EMAIL}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end pr-4 mt-12 md:pr-0">
                    <Button
                        size="large"
                        variant="contained"
                        onClick={handleWant}
                    >
                        {t("want")}
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default Contacts;
