import type { PublicBot, UserRequest } from "./interfaces";
import { api } from "../api";

const publicApi = api.injectEndpoints({
    endpoints: (builder) => ({
        publicBots: builder.query<PublicBot[], void>({
            query: () => "public/bots/",
        }),

        requestUser: builder.mutation<void, UserRequest>({
            query: (body) => ({
                method: "POST",
                url: "public/user_requests/",
                body: JSON.stringify(body),
            }),
        }),
    }),
});

export const { usePublicBotsQuery, useRequestUserMutation } = publicApi;
