import { FC, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useRequestUserMutation } from "app";
import { useFormik } from "formik";
import * as yup from "yup";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
    phone: yup
        .string()
        .matches(
            /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
            "Invalid phone"
        )
        .required("Required"),
    description: yup.string().required("Required"),
});

const Form: FC = () => {
    const { t } = useTranslation();
    const [requestUser] = useRequestUserMutation();
    const [isSuccess, setIsSuccess] = useState(false);
    const { errors, values, handleSubmit, handleChange } = useFormik({
        initialValues: {
            phone: "",
            description: "",
        },
        validateOnChange: false,
        validationSchema: schema,
        onSubmit: async (form) => {
            try {
                await requestUser({
                    phone_number: form.phone,
                    description: form.description,
                }).unwrap();
                setIsSuccess(true);
            } catch (error: any) {
                void error; // @TODO: handle error
            }
        },
    });

    return (
        <div className="flex items-center py-8 bg-white md:py-0 md:min-h-screen">
            <section
                id="form-section"
                className="container flex items-center justify-center h-full mx-auto"
            >
                <Transition
                    show={!isSuccess}
                    leave="transition duration-700"
                    leaveFrom="opacity-100 transform-y-0"
                    leaveTo="opacity-0 transform-y-8"
                >
                    <form
                        className="w-[360px]"
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    >
                        <div className="mb-4">
                            <TextField
                                label={t("phone")}
                                fullWidth
                                name="phone"
                                value={values.phone}
                                error={Boolean(errors.phone)}
                                helperText={errors.phone}
                            />
                        </div>
                        <TextField
                            rows={5}
                            label={t("whyDoYouWantToUsePlatform")}
                            multiline
                            fullWidth
                            name="description"
                            value={values.description}
                            error={Boolean(errors.description)}
                            helperText={errors.description}
                        />
                        <div className="flex justify-end mt-4">
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </div>
                    </form>
                </Transition>
                <Transition
                    show={isSuccess}
                    enter="transition duration-700 delay-200"
                    enterFrom="-translate-y-8 opacity-0"
                    enterTo="translate-y-0 opacity-100"
                >
                    <div className="text-2xl">
                        Thanks, we will get back to you.
                    </div>
                </Transition>
            </section>
        </div>
    );
};

export default Form;
