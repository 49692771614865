import type { FC } from "react";
import { useMeQuery } from "app";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header } from "organisms";
import EuLogoUk from "icons/eu-logo-uk.png";
import EuLogoEn from "icons/eu-logo-en.png";

const UnauthorizedLayout: FC = ({ children }) => {
    const { data: me, isLoading } = useMeQuery();
    const { t, i18n } = useTranslation();
    const logoSrc = i18n.language === "uk" ? EuLogoUk : EuLogoEn;

    if (isLoading) {
        return null;
    }

    if (me) {
        return <Navigate to="/" />;
    }

    return (
        <div className="flex flex-col min-h-screen">
            <Header showLogin />
            {children}
            <div className="sticky bottom-0 z-10 flex flex-wrap w-full bg-white border border-gray-300 md:flex-nowrap">
                <img
                    height="80px"
                    src={logoSrc}
                    className="min-h-[80px] h-[80px] mx-auto md:mx-0"
                />
                <div className="w-full p-2 text-sm md:text-base">
                    {t("fundedByEU")}
                </div>
            </div>
            <footer className="p-4 bg-white border-t border-gray-200">
                <div className="container mx-auto text-sm text-center text-gray-500">
                    <span>{t("copyright")}</span>
                    <br />
                    <span>
                        {t("sourceCode")}{" "}
                        <a href="https://github.com/p1v2/bot-platform-ui">
                            Github UI
                        </a>{" "}
                        {t("sourceCodeBackend")}{" "}
                        <a href="https://github.com/p1v2/bot-platform">
                            Github API
                        </a>
                    </span>
                    <span>{t("sourceCodeContact")}</span>
                </div>
            </footer>
        </div>
    );
};

export default UnauthorizedLayout;
