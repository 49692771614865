const STORAGE_KEY = "auth_token";

let token = window.localStorage.getItem(STORAGE_KEY);

export const getToken = () => token;

export const setToken = (newToken: string) => {
    token = newToken;
    window.localStorage.setItem(STORAGE_KEY, token);
};

export const clearToken = () => {
    token = null;
    window.localStorage.removeItem(STORAGE_KEY);
};
