import { Grid } from "@mui/material";
import { usePublicBotsQuery } from "app";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import PublicBot from "../PublicBot";

const Examples: FC = () => {
    const { data: bots = [] } = usePublicBotsQuery();
    const { t } = useTranslation();

    return (
        <div className="flex items-center min-h-screen mt-4 md:mt-0">
            <section className="container h-full mx-auto">
                <h2 className="text-3xl font-bold text-center md:text-left">
                    {t("landingExamples")}
                </h2>
                <Grid
                    container
                    spacing={4}
                    p={6}
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    {bots.map((bot) => (
                        <Grid key={bot.name} item xs={12} md={4}>
                            <PublicBot bot={bot} />
                        </Grid>
                    ))}
                </Grid>
            </section>
        </div>
    );
};

export default Examples;
